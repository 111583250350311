.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.font-en {
  font-family: 'Alata', 'Roboto Slab', cursive;
}

.font-gr {
  font-family: 'Comfortaa', cursive;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dark {
  background-color: #212529;
  color: #fff;
}

.grey {
  background-color: #2b2926;
  color: #fff;  
}

.light {
  background-color: #f8f9fa;
  color: #333;
}

/* .circle {
  width: 29px;
  height: 29px;
  line-height: 25px;
  background-color: green;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
} */

.stepWrapper {
  display: flex;
  margin-bottom: 20px;
}

.stepBlock .circleWrapper {
  padding: 0px 20px;
  position: relative;
}

.selected .circle {
  color: black;
  background-color: white;
  border-color: green;
}

.stepBlock:not(:last-child)>.circleWrapper::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: green;
  margin: auto;
  z-index: -1;
}

.stepBlock:not(:last-child)>.circleWrapper::after {
  height: 3px;
}

.stepBlock:not(:last-child).selected>.circleWrapper::after,
.stepBlock:not(:last-child).selected~.stepBlock:not(:last-child)>.circleWrapper::after {
  height: 2px;
  opacity: 0.6;
}

.img-holder {
  width: 50px;
  height: 50px;
  border: 2px dotted #131313;
  border-radius: 0.25rem;
  display: flex;
}

.add-img-button {
  color: #8abe57;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
}

.icon-color {
  color: #E0AA22;
}

.icon-color2 {
  color: #6fadf4;
}

.icon-color3 {
  color: #8abe57;
}

.icon-color4 {
  background-color: #131313;
  color: #e0aa22;
}

.require {
  color: red;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact {
  background-color: #E0AA22;
  color: #fff;
}

.contact-btn {
  background-color: #E0AA22 !important;
  color: #fff;
  border: #131313 !important;
}

.contact-btn:hover {
  background-color: #131313 !important;
  color: #fff;
  border: #131313 !important;
}

.blog {
  width: 326px;
  height: 360px;
  background-color: white;
  border-radius: 15px;
  display: inline-grid;
  margin: 60px;
  padding-bottom: 21px;
  z-index: 1;
  position: relative;
}

.blog:hover>* {
  webkit-filter: blur(2px);
  /* Chrome, Safari, Opera */
  filter: blur(2px);
}

.blog>.blog-btn {
  opacity: 0;
  height: 40px;
  position: absolute;
  width: 80px;
  align-self: center;
  justify-self: center;
  z-index: 2;
  transition: all 0.5s ease;
  text-align: center;
  margin-top: 0vmax;
}

.blog:hover>.blog-btn {
  opacity: 1;
  filter: none;
}

.grad {
  background-image: radial-gradient(circle, #E0AA22 10%, #e0aa22 20%, #f8f9fa 40%);
}

.left-side {
  float: left;
}

.left-block {
  width: 50%;
  height: 450px;
  display: inline-block;
  padding-left: 1%;
  padding-right: 1%;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
}

.image2 {
  position: absolute;
  top: 40px;
  left: 100px;

}

.image2:hover {
  top: 40px;
  left: 200px;
}

.fade_out {
  position:relative;
  height:100%;
  width:auto;
  margin:0 auto;
}

.fade_out img {
  position:absolute;
  left:0;
  -webkit-transition: opacity 2s linear;
  -moz-transition: opacity 2s linear;
  transition: opacity 2s linear;
}

.fade_out img.top:hover {
  opacity:0;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
  padding: 5px;
}

.box {
  display: flex;
  align-items:center;
}